<template>
  <base-page-layout :title="$route.meta.title">
    <v-row no-gutters class="justify-end mb-4">
      <mf-button color="primary" icon="settings" label="Configurar build" @click="openBuildConfig" />
    </v-row>
    <mf-card-container
      v-model="search"
      placeholder="Busca (id do cliente, nome do app, nome do cliente)"
      title="Apps"
      description="
        Listagem de apps publicados da Mercafacil.
      "
      use-search
    >
      <template #headers>
        <v-row no-gutters justify="end">
          <v-col cols="auto">
            <v-menu v-model="filters.menu" left offset-x :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-badge class="mr-4" color="primary" :value="filters.filtersQuantity" :content="filters.filtersQuantity" overlap>
                  <v-btn icon v-on="on">
                    <v-icon x-large>filter_list</v-icon>
                  </v-btn>
                </v-badge>
              </template>

              <v-card width="285px" class="pa-4">
                <v-select v-model="filters.selectedType" class="mb-0 pb-3" hide-details :items="filters.appTypes" label="Tipo" outlined @change="refetchApps" />
                <mf-number-input v-model="filters.firebase_version" class="mt-2 mb-8" label="Versão Firebase" @blur="refetchApps" />
                <mf-text-input v-model="filters.android_version_code" class="py-2" label="Android Version Code" @blur="refetchApps" />
                <mf-text-input v-model="filters.android_build_code" class="py-2" label="Android Build Code" @input="refetchApps" />
                <mf-text-input v-model="filters.ios_build_code" class="py-2" label="IOS Build Code" @blur="refetchApps" />
                <mf-text-input v-model="filters.ios_version_code" class="py-2" label="IOS Version Code" @input="refetchApps" />
                <v-btn class="mx-auto mt-0" color="primary" outlined @click="cleanFilters">Limpar Filtros</v-btn>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <apps-index-table :search="search" :filters.sync="filtersSent" :refetch.sync="refetch" :apps-list.sync="selectedApps" />
    </mf-card-container>
    <!-- build config modal -->
    <build-config-modal :dialog.sync="buildConfigDialog" :apps.sync="selectedApps" @close="closeDialog" />
  </base-page-layout>
</template>

<script>
export default {
  name: 'AppBuildLogs',
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    AppsIndexTable: () => import('@/modules/app/views/components/AppsIndexTable.vue'),
    BuildConfigModal: () => import('@/modules/app/views/components/BuildConfigModal.vue')
  },
  data: () => ({
    search: '',
    selectedApps: [],
    buildConfigDialog: false,
    filters: {
      menu: false,
      filtersQuantity: 0,
      appTypes: ['CLUB', 'ECOMMERCE', 'PHYGITAL'],
      selectedType: '',
      firebase_version: 0,
      android_build_code: '',
      android_version_code: '',
      ios_build_code: '',
      ios_version_code: ''
    },
    refetch: false,
    filtersSent: {}
  }),
  methods: {
    openBuildConfig() {
      window.open('https://mercashop.mercafacil.com/admin/aplicativo/aplicativo/', '_blank')
    },
    closeDialog() {
      this.buildConfigDialog = false
      this.selectedApps = []
    },
    refetchApps() {
      let filters = 0
      if (this.filters.selectedType !== '') filters += 1
      if (this.filters.firebase_version !== 0) filters += 1
      if (this.filters.android_build_code !== '') filters += 1
      if (this.filters.android_version_code !== 0) filters += 1
      if (this.filters.ios_build_code !== 0) filters += 1
      if (this.filters.ios_version_code !== '') filters += 1
      this.filters.filtersQuantity = filters

      this.refetch = true
      this.filtersSent = Object.assign(
        {},
        this.filters.selectedType !== '' ? { app_type: this.filters.selectedType?.toLowerCase() } : null,
        this.filters.firebase_version !== 0 ? { firebase_version: this.filters.firebase_version?.toString() } : null,
        this.filters.android_build_code !== '' ? { android_build_code: this.filters.android_build_code?.toString() } : null,
        this.filters.android_version_code !== '' ? { android_version_code: this.filters.android_version_code } : null,
        this.filters.ios_build_code !== '' ? { ios_build_code: this.filters.ios_build_code } : null,
        this.filters.ios_version_code !== '' ? { ios_version_code: this.filters.ios_version_code?.toString() } : null
      )
    },
    cleanFilters() {
      this.filters.selectedType = ''
      this.filters.firebase_version = 0
      this.filters.android_build_code = ''
      this.filters.android_version_code = ''
      this.filters.ios_build_code = ''
      this.filters.ios_version_code = ''

      this.filters.filtersQuantity = 0

      this.filtersSent = {}
      this.refetch = true
    }
  }
}
</script>
